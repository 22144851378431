import './scss/style.scss';
import { useData } from './composables/data.js';
import { createAppRouter } from './router/router.js';
import { createApp } from 'vue';
import App from './vue/core/App.vue';
console.log('running main.js')
const data = useData();

data.fetchData()
    .then(() => {
        console.log('Data fetched successfully:', data.getLoadProgress());
        const router = createAppRouter();
        createApp(App).use(router).mount('#app');
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
    });
