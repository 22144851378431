<template>
    <SectionTemplate :section-data="props.sectionData">
        <form ref="form" id="contact-form" @submit.prevent="_onSubmit">
            <div class="row contact-form-row align-items-stretch">
                <!-- Feedback Alert -->
                <div class="col-12 mb-1" v-if="alertStatus">
                    <Alert :type="alertStatus.type" :message="alertStatus.message" />
                </div>

                <!-- Left Column -->
                <div class="col-lg-6">
                    <!-- Name Input -->
                    <div class="form-group input-group">
                        <span class="input-group-text input-group-attach"><i class="fa fa-signature" /></span>
                        <input v-model="name" class="form-control" id="form-name" type="text" placeholder="Name *"
                            required />
                    </div>

                    <!-- E-mail Address Input -->
                    <div class="form-group input-group">
                        <span class="input-group-text input-group-attach"><i class="fa fa-envelope" /></span>
                        <input v-model="email" class="form-control" id="form-email" type="email" placeholder="E-Mail *"
                            required />
                    </div>

                    <!-- Subject Input -->
                    <div class="form-group input-group">
                        <span class="input-group-text input-group-attach"><i class="fa fa-pen-to-square" /></span>
                        <input v-model="subject" class="form-control" id="form-subject" type="text" placeholder="Subject *"
                            required />
                    </div>
                </div>

                <!-- Right Column -->
                <div class="col-lg-6">
                    <!-- Message TextArea -->
                    <div class="form-group form-group-textarea mb-md-0">
                        <textarea v-model="message" class="form-control" id="form-message" placeholder="Message *"
                            required />
                    </div>
                </div>

                <!-- Bottom Column -->
                <div class="col-12 text-center mt-2 mt-lg-4">
                    <XLButton :label="'Send Message'" :type="'submit'" :icon="'fa fa-envelope me-2'"
                        :id="'btn-submit-message'" :custom-class="{ disabled: submitStatus === SubmitStatus.SENDING }" />
                </div>
            </div>
        </form>
    </SectionTemplate>
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import SectionTemplate from "./templates/SectionTemplate.vue"
import XLButton from "../widgets/XLButton.vue"
import Alert from "../widgets/Alert.vue"
import { useLayout } from "../../composables/layout.js"
import emailjs from 'emailjs-com';
import { EMAILJS_CONFIG } from '../../config';


/**
 * @property {Object} sectionData
 */
const props = defineProps({
    sectionData: Object
})

/**
 * @enum
 */
const SubmitStatus = {
    ENABLED: "enabled",
    SENDING: "sending",
    SENT: "sent",
    ERROR: "error"
}

const layout = useLayout();
const submitStatus = ref(null);
const submitAttempts = ref(0);
const name = ref('');
const email = ref('');
const subject = ref('');
const message = ref('');

/**
 * @private
 */
onMounted(() => {
    submitStatus.value = SubmitStatus.ENABLED
})

/**
 * @return {boolean}
 * @private
 */

const _clearAllFields = () => {
    name.value = '';
    email.value = '';
    subject.value = '';
    message.value = '';
};

const _sendMessage = async () => {
    const feedbackView = layout.getFeedbackView();
    feedbackView.showActivitySpinner('Sending Message...');
    submitAttempts.value++;

    try {
        var response = await emailjs.send(EMAILJS_CONFIG.SERVICE_ID, EMAILJS_CONFIG.TEMPLATE_ID, {
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
        }, EMAILJS_CONFIG.USER_ID)

        if (response.status === 200) {
            _onMessageSent();
        } else {
            _onMessageError();
            console.error('Error sending email:', response);
        }
    } catch (error) {
        _onMessageError();
        console.error('Error sending email:', error);
    }
};

const _onSubmit = () => {
    submitStatus.value = SubmitStatus.SENDING;
    _sendMessage();
};

const _onMessageSent = () => {
    const feedbackView = layout.getFeedbackView();
    feedbackView.hideActivitySpinner();

    _clearAllFields();
    submitStatus.value = SubmitStatus.SENT;
    layout.smoothScrollToElement(props.sectionData['id'], true);
};

const _onMessageError = () => {
    const feedbackView = layout.getFeedbackView();
    feedbackView.hideActivitySpinner();
    submitStatus.value = SubmitStatus.ERROR;
    layout.smoothScrollToElement(props.sectionData['id'], true);
};

/**
 * @return {{type: string, message: String}|null}
 * @private
 */
const alertStatus = computed(() => {
    switch (submitStatus.value) {
        case SubmitStatus.SENT:
            return { type: 'success', message: "The message has been sent. Thanks for reaching out!" }
        case SubmitStatus.ERROR:
            return { type: 'danger', message: "There was an error sending the message. Try again later." }
        default:
            return null
    }
})
</script>

<style lang="scss" scoped>
@import "/src/scss/_theming.scss";

$input-background-color: lighten($dark, 5%);
$input-border-color: lighten($dark, 12%);
$input-text-color: $white;
$input-placeholder-text-color: darken($light-6, 7%);

$input-focus-background-color: $dark;
$input-focus-border-color: $primary;
$input-focus-text-color: $white;

$input-attach-background-color: lighten($dark, 1%);
$input-attach-border-color: lighten($dark, 15%);
$input-attach-text-color: $light-5;

$input-border-width: 2px;

.contact-form {
    @include media-breakpoint-down(lg) {
        max-width: 680px;
        margin: 0 auto;
    }
}

input,
textarea {
    padding: 1rem;
    background-color: $input-background-color;
    border-color: $input-border-color;
    border-width: $input-border-width;
    color: $input-text-color;

    &:focus {
        background-color: $input-focus-background-color;
        border-color: $input-focus-border-color;
        color: $input-focus-text-color;
    }
}

.input-group {
    margin-bottom: 0.8rem;
    @include media-breakpoint-down(sm) {
        margin-bottom: 0.4rem;
    }
}

.input-group-text {
    min-width: 60px;
    text-align: center;

    background-color: $input-attach-background-color;
    border-color: $input-attach-border-color;
    color: $input-attach-text-color;

    i {
        margin: 0 auto;
    }
}

input {
    height: 65px;
    @include media-breakpoint-down(lg) {
        height: 55px;
    }
    @include media-breakpoint-down(sm) {
        height: min(8vh, 50px);
        font-size: 0.8rem;
    }
}

textarea {
    height: 220px;

    @include media-breakpoint-down(lg) {
        height: 200px;
    }
    @include media-breakpoint-down(sm) {
        height: 150px;
        font-size: 0.8rem;
    }
}

::-webkit-input-placeholder {
    --font-size: 1rem;
    @include media-breakpoint-down(lg) {
        --font-size: 0.9rem;
    }

    font-size: var(--font-size);
    color: $input-placeholder-text-color;
}
</style>